import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { adminPost } from '../utils/requests';

import style from './RegisterPage.module.scss';

export default function RegisterPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const register = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (password !== repeatedPassword) {
      setError('The two passwords do not match.');
      return;
    } else if (password.length < 8) {
      setError('The password must have at least 8 characters.');
    } else {
      setError('');
      try {
        await adminPost('/register', { email, password });
        history.push('/login');
      } catch (e) {
        setError(e.message);
      }
    }
  };

  return (
    <div className={style.formContainer}>
      <h1>Register</h1>
      <form>
        <div className={style.formRow}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={style.formRow}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="at least 8 characters"
          />
        </div>
        <div className={style.formRow}>
          <label htmlFor="repeated_password">Repeat</label>
          <input
            id="repeatedPassword"
            name="repeatedPassword"
            type="password"
            value={repeatedPassword}
            onChange={e => setRepeatedPassword(e.target.value)}
          />
        </div>
        <div>{error}</div>
        <div className={style.formRow}>
          <input type="submit" value="Register" onClick={register} />
          <Link className={style.loginLink} to="/login">
            Login
          </Link>
        </div>
      </form>
    </div>
  );
}
